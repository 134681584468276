import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import Contact from '../Components/Contact'

const Info = () => {
  return (
    <>
        <Navbar />
        <Contact />
        <Footer />
    </>
  )
}

export default Info